@use "vars.css";
$mobile: 600px;
.container {
  background-color: var(--header-bg-darker);
  color: #fff;
  height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  font-family: "Mali-SemiBold" !important;
  .bg {
    max-width: 1440px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @media (max-width: 700px) {
      width: 80%;
    }
    @media (max-width: 600px) {
      width: 95%;
    }
    img {
      width: 190px;
      height: auto;
      @media (max-width: 1000px) {
        width: 140px;
      }
      @media (max-width: $mobile) {
        width: 130px;
      }
    }
    .title {
      font-size: 23px;
      font-weight: 400;
      letter-spacing: 1px;
      @media (max-width: $mobile) {
        font-size: 16px;
        text-align: center;
        display: none;
      }
    }
  }
}

.nav-link {
  text-decoration: none;
  color: #a86d60;
  font-weight: 500;
  margin-bottom: 4px;
  display: block;
  width: 100%;
  opacity: 0.8;
  &:hover {
    color: #a86d60;
  }
}

.active {
  opacity: 1;
  font-weight: 600;
}

.line{
  height: 1px;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: 8px;
  background-color: rgba(131, 63, 29, 0.3);
}
