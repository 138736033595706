@use "vars.css";
.container {
  padding: var(--gap-to-header) 32px 60px 20px;
  width: 280px;
  // background: var(--sidebar-bg);
  // background: #f5f2f0;
  border: 1px solid #bea392;
  // min-height: calc(100vh - 70px);
  margin-top: 60px;
  border-radius: 12px;
  height: max-content;
  font-weight: 600;
  .title {
    color: var(--label-color);
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 8px;
    svg {
      font-size: 30px;
    }
  }
  .navs {
    display: flex;
    margin-top: 38px;
    flex-direction: column;
    a {
      text-decoration: none;
    }
    .nav {
      // background: var(--sidebar-active);
      padding: 16px 20px;
      color: #a86d60;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 12px;
      svg {
        fill: #a86d60;
        stroke: #a86d60;
      }

      &--active {
        background: #833f1d;
        border-radius: 4px;
        color: #fff;
        svg {
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }
}
