@use "vars.css";
.main-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  padding: 40px 0px 40px 60px;
  background: #fdecd9;
  @media (max-width: 600px) {
    padding: 20px 12px 20px 12px !important;
  }
  @media (min-width: 900px) {
    // width: calc(100vw - 256px);
  }
}
.content {
  margin: 0 auto;
  // width: 80%;
  height: 100%;

  @media (max-width: 600px) {
    // padding: 20px 10px 20px 10px;
    width: 100%;
  }
  @media (max-width: 1200px) and (min-width: 900px) {
    width: 100%;
    max-width: 100%;
  }
}
