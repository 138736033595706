:root{
  --primary-color: #f1712a;
  --header-bg: #f7f4f2;
  --bg-color: #f0e9e7;
  --border-color: #e2d6cf;
  --text-color: #aa9585;
  --link-color: #5b3926;
  --heading-color: #5b3926;
  --red-color: #F25858;
  
  --header-bg-darker: #944850;
  --sidebar-bg: rgba(253, 245, 232, 1);
  --sidebar-active: #833F1D;
  --label-color: #833F1D;
  --content-bg-color: #FFFAF5;
  --label-page-color: #9B3A44;
  --text-color-second: #867064;
  --bg-btn-color: #F88D54;
  --white-bg: #f7f7f7;
  --bg-color-content: #FDF5E8;


  --active-color: #EEFFEA;
  --closed-color: #FFEAEA;
  --pending-color: #6b7280;
  --gap-to-header: 40px;
  --label-page-size: 24px;
}
