$table: 1000px;
.bg {
  background: #fdecd9 !important;
}
.app {
  display: flex;
  width: 80%;
  max-width: 1440px;
  margin: auto;
  padding-bottom: 40px;
  @media (max-width: $table) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
