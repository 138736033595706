@use "vars.css";
.container {
  color: var(--header-bg);
  background-color: #d2414c;
  font-size: 16px !important;
  padding: 10px 18px;
  border-radius: 24px;
  font-size: 17px !important;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
  position: relative;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    margin-bottom: 12px;
    padding: 6px 8px;
    font-size: 15px !important;
  }
}
