@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/Calibre-Medium-Custom.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/Calibre-Semibold-Custom.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Mali-SemiBold";
  src: local("Mali-SemiBold"), url(./assets/fonts/Mali-SemiBold.ttf) format("truetype");
}

body {
  min-height: 100vh;
  font-family: 'Open Sans', "Roboto" , -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  box-sizing: border-box;
}
